











































































































import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Drag, Drop } from 'vue-drag-drop';
import JwlTaskMeta from '@/components/jwlTaskMeta.vue';
import JwlButton from '@/components/JwlButton.vue';
import { QuizAnswer as QuizAnswerInterface, QuizQuestion } from '@/store/interface/Quiz';

const FormElement = () => import('common-modules/src/components/CommonFormElement.vue');

@Component({
  components: {
    FormElement,
    JwlButton,
    JwlTaskMeta,
    VueDrag: Drag,
    VueDrop: Drop,
  },
})
export default class QuizAnswer extends Vue {
  @Prop(Object)
  quizQuestion!: QuizQuestion;

  @Prop(Boolean)
  isPublished!: boolean;

  selectedAnswers: number[] = [];
  dragPairs: number[][] = [];
  dropOver: number | null = null;
  draggedItem: QuizAnswerInterface | null = null;

  @Watch('quizQuestion')
  initData (): void {
    this.selectedAnswers = [];
    this.dragPairs = [];
    if (this.quizQuestion.answerIds) {
      this.selectedAnswers = this.quizQuestion.answerIds;
    } else if (this.quizQuestion.answerDragAndDrop) {
      this.dragPairs = this.quizQuestion.answerDragAndDrop;
    }
  }

  fieldName (answerOption: QuizAnswerInterface): string {
    return this.quizQuestion.questionType === 'single_response'
      ? 'question'
      : answerOption.id.toString();
  }

  handleSelectChange (answerOptionId: number, selected: boolean): void {
    if (selected) {
      if (!this.selectedAnswers.includes(answerOptionId)) {
        this.selectedAnswers.push(answerOptionId);
      }
    } else {
      this.selectedAnswers = this.selectedAnswers.filter((answer) => answer !== answerOptionId);
    }
  }

  handleDrop (dragItem: QuizAnswerInterface, dropItem: QuizAnswerInterface): void {
    const dragItemFoundIndex = this.dragPairs.findIndex((dragPair) => dragPair.includes(dragItem.id));
    if (dragItemFoundIndex >= 0) {
      this.dragPairs.splice(dragItemFoundIndex, 1);
    }

    const dropItemFoundIndex = this.dragPairs.findIndex((dragPair) => dragPair.includes(dropItem.id));
    if (dropItemFoundIndex >= 0) {
      this.dragPairs.splice(dropItemFoundIndex, 1);
    }

    this.dragPairs.push([
      dragItem.id,
      dropItem.id,
    ]);
    this.draggedItem = null;
  }

  hasDrop (dropItem: QuizAnswerInterface): QuizAnswerInterface | undefined {
    const pair = this.dragPairs.find((dragPair) => dragPair.includes(dropItem.id));
    if (pair) {
      return this.dragOptions.find((dragOption) => pair.includes(dragOption.id));
    }

    return undefined;
  }

  hasDrag (dragItem: QuizAnswerInterface): boolean {
    return !!this.dragPairs.find((dragPair) => dragPair.includes(dragItem.id));
  }

  showDrop (dropArea: QuizAnswerInterface): boolean {
    return !!this.draggedItem && this.dropOver === dropArea.id;
  }

  dragItemClasses (dragItem: QuizAnswerInterface): Record<string, boolean> {
    return {
      'quiz-answer__drag-item--dragging': this.draggedItem?.id === dragItem.id,
      'quiz-answer__drag-item--dragged': this.hasDrag(dragItem),
      'quiz-answer__drag-item--published': this.isPublished,
    };
  }

  droppedItemClasses (dragItem: QuizAnswerInterface): Record<string, boolean> {
    return {
      'quiz-answer__dropped-item--dragging': this.draggedItem?.id === dragItem.id,
      'quiz-answer__dropped-item--dragged': this.hasDrag(dragItem),
      'quiz-answer__dropped-item--published': this.isPublished,
    };
  }

  get isSelectResponseType (): boolean {
    return ['single_response', 'multiple_response'].includes(this.quizQuestion.questionType);
  }

  get serializedAnswers (): string {
    return this.selectedAnswers.join(',');
  }

  get fieldType (): string {
    return this.quizQuestion.questionType === 'single_response'
      ? 'radio'
      : 'boolean';
  }

  get rootClasses (): Record<string, boolean> {
    return {
      'quiz-answer--single-response': this.quizQuestion.questionType === 'single_response',
      'quiz-answer--multiple-response': this.quizQuestion.questionType === 'multiple_response',
      'quiz-answer--drag-and-drop': this.quizQuestion.questionType === 'drag_and_drop',
      'quiz-answer--drag-in-progress': this.draggedItem !== null,
      'quiz-answer--fill-text': this.quizQuestion.questionType === 'fill_text',
    };
  }

  get dragOptions (): QuizAnswerInterface[] {
    return this.quizQuestion.answerOptions.filter((answerOption) => !answerOption.isDrop);
  }

  get dropOptions (): QuizAnswerInterface[] {
    return this.quizQuestion.answerOptions.filter((answerOption) => answerOption.isDrop);
  }

  get stringifyDragPairs (): string {
    const pairs: string[] = [];
    this.dragPairs.forEach((dragPair) => {
      pairs.push(dragPair.join(','));
    });
    return pairs.join(';');
  }

  mounted (): void {
    this.initData();
  }
}
