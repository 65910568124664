
















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import JwlTaskMeta from '@/components/jwlTaskMeta.vue';
import JwlButton from '@/components/JwlButton.vue';
import { ExtendedRunTask } from '@/store/interface/Task';

@Component({
  components: {
    JwlButton,
    JwlTaskMeta,
  },
})
export default class QuizStart extends Vue {
  @Prop(Object)
  taskQuiz!: ExtendedRunTask;
}
