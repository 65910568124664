



















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import JwlButton from '@/components/JwlButton.vue';
import { QuizQuestion as QuizQuestionInterface } from '@/store/interface/Quiz';
import QuizAnswer from '@/components/quiz/QuizAnswer.vue';

@Component({
  components: {
    JwlButton,
    QuizAnswer,
  },
})
export default class QuizQuestion extends Vue {
  @Prop(Number)
  maxQuestions!: number;

  @Prop(Number)
  currentQuestionIndex!: number;

  @Prop(Object)
  quizQuestion!: QuizQuestionInterface;

  @Prop(Boolean)
  isPublished!: boolean;

  twoDigit (number: number): string {
    if (number < 10) {
      return `0${number}`;
    }

    return number.toString();
  }

  get pageNumber (): string {
    return this.twoDigit(this.currentQuestionIndex + 1);
  }

  get lastPageNumber (): string {
    return this.twoDigit(this.maxQuestions);
  }
}
