






















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import {
  QuizQuestion as QuizQuestionInterface,
} from '@/store/interface/Quiz';
import BackBar from '@/components/BackBar.vue';
import TaskIcon from '@/components/TaskIcon.vue';
import JwlButton from '@/components/JwlButton.vue';
import QuizStart from '@/components/quiz/QuizStart.vue';
import { ExtendedRunTask } from '@/store/interface/Task';
import QuizQuestion from '@/components/quiz/QuizQuestion.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');
const QuizEnd = () => import('@/components/quiz/QuizEnd.vue');

type SingleSubmitResponse =
  { key: 'answerString', value: string }
  | { key: 'answerIds', value: number[] }
  | { key: 'answerDragAndDrop', value: number[][] };

@Component({
  components: {
    JwlSubmitRow,
    BackBar,
    JwlButton,
    CommonError,
    CommonIcon,
    QuizEnd,
    QuizQuestion,
    QuizStart,
    TaskIcon,
  },
})
export default class JwlTaskQuiz extends Vue {
  $refs!: {
    quizForm: HTMLFormElement;
  }

  task: ExtendedRunTask | null = null;
  questions: QuizQuestionInterface[] = [];
  loadingState = 0;
  error = null;
  syncError: any[] = [];
  showStart = true;
  showEnd = false;
  activeQuestion: number | null = null;
  syncIndex: number[] = [];
  errorIndex: number[] = [];
  successIndex: number[] = [];
  errors: Record<number, string> = {};

  setActiveQuestion (question: 'start' | 'end' | number): void {
    if (!this.showStart && !this.showEnd && this.activeQuestion !== null && !this.isPublished) {
      this.handleSubmit(this.activeQuestion).then(() => {
        this.switchActiveQuestion(question);
      });
    } else {
      this.switchActiveQuestion(question);
    }
  }

  switchActiveQuestion (question: 'start' | 'end' | number): void {
    if (question === 'start') {
      this.showStart = true;
      this.showEnd = false;
      this.activeQuestion = null;
    } else if (question === 'end') {
      this.showEnd = true;
      this.showStart = false;
      this.activeQuestion = null;
    } else if (question >= this.questions.length) {
      this.setActiveQuestion('end');
    } else if (question < 0) {
      this.setActiveQuestion('start');
    } else {
      this.showStart = false;
      this.showEnd = false;
      this.activeQuestion = question;
    }
  }

  handleSubmit (index: number): Promise<void> {
    return new Promise((resolve) => {
      if (this.$refs.quizForm && this.activeQuestion !== null) {
        const formData = new FormData(this.$refs.quizForm);
        const currentIndex = Number(index.toString());
        const formQuestion = this.questions[currentIndex];
        this.syncIndex.push(currentIndex);
        resolve();
        this.$store.dispatch('postData', {
          url: `task/${this.task?.id}/quiz/${formQuestion.id}`,
          formData,
        }).then((data: SingleSubmitResponse) => {
          if (data.key === 'answerIds') {
            this.questions[currentIndex].answerIds = data.value;
          } else if (data.key === 'answerString') {
            this.questions[currentIndex].answerString = data.value;
          } else if (data.key === 'answerDragAndDrop') {
            this.questions[currentIndex].answerDragAndDrop = data.value;
          }
          this.syncIndex = this.syncIndex.filter((syncIndex) => syncIndex !== currentIndex);
          this.successIndex.push(currentIndex);
          setTimeout(() => {
            this.successIndex = this.successIndex.filter((localIndex) => localIndex !== currentIndex);
          }, 2500);
        }).catch((e) => {
          this.syncError.push(e);
          this.errorIndex.push(currentIndex);
        });
      } else {
        resolve();
      }
    });
  }

  goNext (): void {
    if (this.activeQuestion !== null && this.activeQuestion >= 0) {
      this.setActiveQuestion(this.activeQuestion + 1);
    } else {
      this.setActiveQuestion(0);
    }
  }

  goPrevious (): void {
    if (this.activeQuestion !== null && this.activeQuestion >= 0) {
      this.setActiveQuestion(this.activeQuestion - 1);
    } else {
      this.setActiveQuestion(this.questions.length - 1);
    }
  }

  twoDigit (number: number): string {
    if (number < 10) {
      return `0${number}`;
    }

    return number.toString();
  }

  iconName (index: number): string | undefined {
    if (this.errorIndex.includes(index)) {
      return 'exclamation-circle';
    }

    if (this.syncIndex.includes(index)) {
      return 'spinner-third';
    }

    const submitted = this.questions[index];
    if (submitted) {
      const hasIds = submitted.answerIds !== undefined && submitted.answerIds.length > 0;
      const hasString = submitted.answerString !== undefined && submitted.answerString !== null && submitted.answerString.length > 0;
      const hasDragAndDrop = submitted.answerDragAndDrop !== undefined && submitted.answerDragAndDrop.length > 0;
      if (hasIds || hasString || hasDragAndDrop) {
        return 'check-circle';
      }
    }

    return undefined;
  }

  fetchData (): void {
    this.loadingState = 1;
    this.$store.dispatch('getData', `task/${this.$route.params.task}`)
      .then((data) => {
        this.task = data.task;
        this.questions = data.work;
        this.loadingState = 2;
      })
      .catch((e) => {
        this.error = e;
        this.loadingState = -1;
      });
  }

  get currentTaskSyncStatus (): number {
    if (this.activeQuestion !== null) {
      if (this.syncIndex.includes(this.activeQuestion)) {
        return 1;
      }
      if (this.errorIndex.includes(this.activeQuestion)) {
        return -1;
      }
      if (this.successIndex.includes(this.activeQuestion)) {
        return 2;
      }
      return 0;
    }

    return -1;
  }

  get rootClasses (): Record<string, boolean> {
    return {
      'jwl-task-quiz--bachelor': this.task?.programmeType === 'bachelor',
      'jwl-task-quiz--professional': this.task?.programmeType === 'professional',
      'jwl-task-quiz--teacher-training': this.task?.programmeType === 'teacher-training',
      'jwl-task-quiz--academy': this.task?.programmeType === 'academy',
    };
  }

  get isPublished (): boolean {
    return !!this.task?.submitted.hasPublished;
  }

  get hasAnswers (): boolean {
    let hasAnswers = false;

    if (this.questions) {
      this.questions.forEach((question) => {
        const hasSingleResponse = question.questionType === 'single_response' && question.answerIds && question.answerIds.length > 0;
        const hasMultiResponse = question.questionType === 'multiple_response' && question.answerIds && question.answerIds.length >= 1;
        const hasDragAndDrop = question.questionType === 'drag_and_drop' && question.answerDragAndDrop && question.answerDragAndDrop.length > 0;
        const hasFillText = question.questionType === 'fill_text' && question.answerString && question.answerString.length > 0;
        if (hasSingleResponse || hasMultiResponse || hasDragAndDrop || hasFillText) {
          hasAnswers = true;
        }
      });
    }

    return hasAnswers;
  }

  mounted (): void {
    this.fetchData();
  }
}
